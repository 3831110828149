import Container from 'common/components/container/Container';
import PageSection from 'common/components/pageSection/PageSection';
import Selector from 'common/components/selector/Selector';
import { type Data } from 'common/components/selector/SelectorModel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UniqueFeatures.module.scss';

const UniqueFeatures: React.FC = () => {
    const { t } = useTranslation();
    const data: Data = t('home.unique_features', { returnObjects: true }) ?? [];

    return (
        <Container >
            <PageSection
                title={data.title}
                contentClassName={styles.content}
                colorMode="dark"
            >
                <Selector data={data} colorMode={'dark'}></Selector>
            </PageSection>
        </Container>
    );
};

export default UniqueFeatures;
