import Container from 'common/components/container/Container';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AppAdvantages.module.scss';
import DivHtml from 'common/components/divHtml/DivHtml';

export interface Advantage {
    name: string;
    text: string;
}

const AppAdvantages: React.FC = () => {
    const { t } = useTranslation();
    const advantages: Advantage[] = t('home.app_advantages.advantages', { returnObjects: true }) ?? [];

    return (
        <div className={styles.background}>
            <div className={styles.fade} />
            <Container className={styles.container}>
                <DivHtml className={styles.title} html={t('home.app_advantages.title')} />
                <img className={styles.image} src={t('home.app_advantages.image_url') ?? ''} />
                <div className={styles.grid}>
                    {advantages.map((advantage, index) => (
                        <div key={index} className={styles.column}>
                            <div className={styles.name}>{advantage.name}</div>
                            <div className={styles.text}>{advantage.text}</div>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default AppAdvantages;
