import Button from 'common/components/button/Button';
import Container from 'common/components/container/Container';
import PageSection from 'common/components/pageSection/PageSection';
import { useTranslation } from 'react-i18next';
import styles from './ClearPricing.module.scss';
import { useContext } from 'react';
import { SubscribeWaitListModalContext } from 'common/components/subscribeWaitListModal/SubscribeWaitListModal';
import colors from 'styles/export/colors.module.scss';
import DivHtml from 'common/components/divHtml/DivHtml';

export interface Subscriptions {
    title: string;
    subscription_models: SubscriptionMoodels[]
}

interface SubscriptionMoodels {
    title: string;
    tag: string;
    subTitle: string;
    price: string;
    features_title: string;
    button_text: string;
    button_opaque: boolean;
    button_url: string;
    features: Feature[]
}

interface Feature {
    feature: string
}

const ClearPricing: React.FC = () => {
    const { t } = useTranslation();
    const subscriptions: Subscriptions = t('home.clear_pricing', { returnObjects: true }) ?? [];
    const { open: openSubscribeWaitListModal } = useContext(SubscribeWaitListModalContext);

    return (
        <Container >
            <PageSection title={subscriptions.title} colorMode="dark" >
                <div className={styles.pricing_mainDiv}>
                    {subscriptions.subscription_models.map((subscription, index) => (

                        <div key={index} className={styles.pricing_subDiv}>
                            <div className={styles.innerDiv}>
                                <div style={{ textAlign: 'center' }}>
                                    <div className={styles.subscription_title}>{subscription.title} </div>
                                    <div className={styles.subscription_sub_title}>{subscription.subTitle} </div>
                                    <DivHtml className={styles.subscription_price} html={subscription.price} />
                                    <div className={styles.subscription_features_title}> {subscription.features_title}</div>
                                    <div className={styles.subscription_features_list}>
                                        {subscription.features.map((feature, ii) => (

                                            <div
                                                key={ii}
                                                className={styles.subscription_feature}
                                            >
                                                {feature.feature}
                                            </div>

                                        ))}
                                    </div>
                                </div>
                                <div className={styles.buttonDiv}>
                                    <Button
                                        color={subscription.button_opaque ? colors.basicGrayDarker : colors.primaryYellow}
                                        opaque={subscription.button_opaque}
                                        className={styles.buttonWidth}
                                        onClick={() => {
                                            if (subscription.button_url) {
                                                window.open(subscription.button_url);
                                            } else {
                                                openSubscribeWaitListModal('dark');
                                            }
                                        }}
                                    >
                                        {subscription.button_text.toUpperCase()}
                                    </Button>
                                </div>

                            </div>
                            <div className={styles.tag} >{subscription.tag}</div>
                        </div>

                    ))}
                </div>
            </PageSection>
        </Container>
    );
};

export default ClearPricing;
