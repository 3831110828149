import Button from 'common/components/button/Button';
import PageHeader from 'common/components/pageHeader/PageHeader';
import useTitle from 'common/hooks/useTitle';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/export/colors.module.scss';
import styles from './FanPage.module.scss';
import { SubscribeWaitListModalContext } from 'common/components/subscribeWaitListModal/SubscribeWaitListModal';
import ArtysLogo from 'common/components/logo/ArtysLogo';
import UniqueFeatures from './uniqueFeatures/UniqueFeatures';
import HeaderWaves from 'common/components/waves/HeaderWaves';
import Technology from 'common/components/technology/Technology';
import Footer from 'common/components/footer/Footer';
import FooterWaves from 'common/components/waves/FooterWaves';
import ClearPricing from './clearPricing/ClearPricing';
import Sponsors from 'pages/artist/sponsors/Sponsors';
import AppAdvantages from './appAdvantages/AppAdvantages';
import Advisors from '../../common/components/advisors/Advisors';

const FanPage: React.FC = () => {
    const { t } = useTranslation();
    const { open: openSubscribeWaitListModal } = useContext(SubscribeWaitListModalContext);
    useTitle(t('home.page_title'));

    const onTogglePage = () => {
        window.open(t('home.header.toggle_url', { interpolation: { escapeValue: true } }) ?? '#', '_self');
    };

    const onBuyLicense = () => {
        window.open(t('home.header.buy_license_button_url', { interpolation: { escapeValue: true } }) ?? '#', '_new');
    };

    const onSignupWaitlist = () => {
        openSubscribeWaitListModal('dark');
    };

    return (
        <div className={styles.container}>
            <HeaderWaves
                wavesUrl={t('home.header.waves_url', { interpolation: { escapeValue: true } })}
                wavesMobileUrl={t('home.header.waves_mobile_url', { interpolation: { escapeValue: true } })}
            />
            <div className={styles.innerContainer}>
                <PageHeader
                    title={t('home.header.title', { interpolation: { escapeValue: false } })}
                    description={t('home.header.description', { interpolation: { escapeValue: false } })}
                    colorMode="dark"
                    forArtistText={t('home.header.for_artist')}
                    forFanText={t('home.header.for_fan')}
                    logo={<ArtysLogo />}
                    backgroundUrl={t('home.header.background_url', { interpolation: { escapeValue: true } })}
                    backgroundMobileUrl={t('home.header.background_mobile_url', { interpolation: { escapeValue: true } })}
                    onToggle={onTogglePage}
                    toggleValue={false}
                    buttons={[
                        <Button
                            key={'buy-license'}
                            color={colors.primaryYellow}
                            textColor={colors.basicWhite}
                            onClick={onBuyLicense}
                        >
                            {t('home.header.buy_license_button').toUpperCase()}
                        </Button>,
                        <Button
                            key={'signup-waitlist'}
                            color={colors.transparent}
                            textColor={colors.primaryYellow}
                            onClick={onSignupWaitlist}
                        >
                            <img src={t('home.header.signup_waitlist_button_icon_url', { interpolation: { escapeValue: true } }) ?? ''} className={styles.headerButtonIcon} />
                            {t('home.header.signup_waitlist_button').toUpperCase()}
                        </Button>
                    ]}
                />

                <AppAdvantages />

                <UniqueFeatures />

                <ClearPricing />

                <Technology colorMode="dark"/>

                <Advisors colorMode="dark" />

                <Sponsors colorMode="dark" />

                <FooterWaves
                    wavesUrl={t('footer.waves_url', { interpolation: { escapeValue: true } })}
                />

                <Footer
                    colorMode="dark"
                    logo={<ArtysLogo type="light" />}
                />
            </div>
        </div>
    );
};

export default FanPage;
