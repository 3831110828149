import styles from './FooterWaves.module.scss';
import useIsMobile from 'common/hooks/useIsMobile';

interface Props {
    wavesUrl: string;
}

const FooterWaves = ({ wavesUrl }: Props) => {
    const isMobile = useIsMobile();

    if (!isMobile) {
        return <img className={styles.waves} src={wavesUrl} />;
    }

    return null;
};

export default FooterWaves;
